import {
  GET_RESTAURANT_INFO,
  GET_RESTAURANT_INFO_BY_ID,
  GET_RESTAURANT_ITEMS,
  RESET_INFO,
  RESET_ITEMS,
  SINGLE_ITEM,
  SEARCH_ITEM,
  CLEAR_SEARCH,
  SET_FAVORITE_REST,
  SET_MAPPED_REST,
  GET_RESTAURANT_INFO_FOR_LOGGED_IN_USER,
} from "./actionTypes";
//RESET_BACKUP

import { FORCE_RELOAD } from "../helper/actionTypes";

import {
  GET_RESTAURANT_INFO_BY_ID_URL,
  GET_RESTAURANT_INFO_URL,
  GET_RESTAURANT_ITEMS_URL,
  GET_SINGLE_ITEM_URL,
  GET_RESTAURANT_INFO_AND_OPERATIONAL_STATUS_URL,
  ADD_TO_FAVORITE_RESTAURANT_URL,
  GET_FAVORITE_RESTAURANT_FOR_LOGGED_IN_URL,
  ADD_TO_MAPPED_RESTAURANT_URL,
} from "../../configs";
import { updateCart } from "../total/actions";

import Axios from "axios";
// import FuzzySearch from "fuzzy-search";
// import { WEBSITE_URL } from "../../configs/website";

export const setSingleItem = (product) => ({
  type: SINGLE_ITEM,
  payload: product,
});

export const getRestaurantInfoAndOperationalStatus = (
  id,
  latitude,
  longitude
) => (dispatch) => {
  return Axios.post(GET_RESTAURANT_INFO_AND_OPERATIONAL_STATUS_URL, {
    id: id,
    latitude: latitude,
    longitude: longitude,
  })
    .then((response) => {
      const restaurant_info = response.data;
      return dispatch({ type: GET_RESTAURANT_INFO, payload: restaurant_info });
    })
    .catch(function(error) {
      console.log(error);
      if (error.response.status === 400) {
        const force_reload = true;
        return dispatch({ type: FORCE_RELOAD, payload: force_reload });
      }
    });
};

export const getRestaurantInfo = (slug) => (dispatch, getState) => {
  let user = getState().user.user;
  let payload = {
    token: "",
    user_id: "",
  };
  if (user && user.data) {
    payload.token = user.data.auth_token;
    payload.user_id = user.data.id;
  }
  return Axios.post(GET_RESTAURANT_INFO_URL + "/" + slug, payload)
    .then((response) => {
      const restaurant_info = response.data;
      return dispatch({
        type: GET_RESTAURANT_INFO,
        payload: restaurant_info,
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const getRestaurantInfoForLoggedInUser = (slug) => (
  dispatch,
  getState
) => {
  return Axios.post(GET_FAVORITE_RESTAURANT_FOR_LOGGED_IN_URL + "/" + slug, {
    token: getState().user.user.data.auth_token,
    user_id: getState().user.user.data.id,
  })
    .then((response) => {
      const restaurant_info = response.data;
      return dispatch({
        type: GET_RESTAURANT_INFO_FOR_LOGGED_IN_USER,
        payload: restaurant_info,
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const getRestaurantInfoById = (id, lat, lng) => (dispatch, getState) => {
  return Axios.post(GET_RESTAURANT_INFO_BY_ID_URL + "/" + id, {
    latitude: lat,
    longitude: lng,
    user_id: getState().user.user.data.id,
  })
    .then((response) => {
      const restaurant_info = response.data;
      dispatch({
        type: GET_RESTAURANT_INFO_BY_ID,
        payload: restaurant_info,
      });
      return restaurant_info;
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const getRestaurantItems = (slug) => (dispatch, getState) => {
  Axios.post(GET_RESTAURANT_ITEMS_URL + "/" + slug, {
    token: getState().user.user.data.auth_token,
    user_id: getState().user.user.data.id,
  })
    .then((response) => {
      const restaurant_items = response.data;

      Object.keys(restaurant_items.items).map((category, index) => {
        restaurant_items.items[category].map((item) => {
          item.desc = "";
        });
      });
      return dispatch({
        type: GET_RESTAURANT_ITEMS,
        payload: restaurant_items,
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const getSingleItem = (id) => (dispatch, getState) => {
  let user = getState().user.user;
  let payload = { token: "", user_id: "", id: id };
  if (user && user.data) {
    payload.token = user.data.auth_token;
    payload.user_id = user.data.id;
  }
  return Axios.post(GET_SINGLE_ITEM_URL, payload)
    .then((response) => {
      const item = response.data;
      // let itemDetails = {
      //   id: item.id,
      //   desc: "",
      //   recommended: [],
      //   compare_items: [],
      //   also_bought: [],
      // };
      // if (item.desc && item.id === itemDetails.id) {
      //   itemDetails = { desc: item.desc, id: item.id };
      //   item.desc = "";
      // }
      // if (
      //   item.recommended &&
      //   item.recommended.length &&
      //   item.id === itemDetails.id
      // ) {
      //   itemDetails.recommended = item.recommended;
      //   item.recommended = [];
      // }
      // if (
      //   item.compare_items &&
      //   item.compare_items.length &&
      //   item.id === itemDetails.id
      // ) {
      //   itemDetails.compare_items = item.compare_items;
      //   item.compare_items = [];
      // }
      // if (
      //   item.also_bought &&
      //   item.also_bought.length &&
      //   item.id === itemDetails.id
      // ) {
      //   itemDetails.also_bought = item.also_bought;
      //   item.also_bought = [];
      // }

      dispatch({
        type: SINGLE_ITEM,
        payload: item.id,
      });
      return item;
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const resetItems = () => (dispatch) => {
  const empty = [];
  return dispatch({ type: RESET_ITEMS, payload: empty });
};
// export const resetBackup = () => (dispatch) => {
//   const empty = [];
//   return dispatch({ type: RESET_BACKUP, payload: empty });
// };

export const resetInfo = () => (dispatch) => {
  const empty = [];
  return dispatch({ type: RESET_INFO, payload: empty });
};

export const searchItem = (
  itemList,
  itemName,
  searchFoundText,
  noResultText
) => (dispatch, getState) => {
  const searchResultText = searchFoundText + itemName;
  const noSearchFoundText = noResultText + itemName;

  let foodItems = [];

  foodItems = itemList.filter((eachItem) => {
    const itemNames = eachItem.name.toLowerCase();
    return itemNames.includes(itemName.toLowerCase());
  });

  //console.log("searchResult", searchResult);

  // const searcher = new FuzzySearch(itemList, ["name"], {
  //   caseSensitive: false,
  // });

  //foodItems = searcher.search(itemName);

  if (foodItems.length > 0) {
    return dispatch({
      type: SEARCH_ITEM,
      payload: { items: { [searchResultText]: foodItems } },
    });
  } else if (foodItems.length <= 0) {
    return dispatch({
      type: SEARCH_ITEM,
      payload: { items: { [noSearchFoundText]: foodItems } },
    });
  }
};

export const sortItems = (itemList, selectedOption) => (dispatch) => {
  let store = [];
  if (selectedOption === "2") {
    store = itemList.sort(
      (a, b) => parseFloat(b.price.toString()) - parseFloat(a.price.toString())
    );
  } else if (selectedOption === "3") {
    store = itemList.sort(
      (a, b) => parseFloat(a.price.toString()) - parseFloat(b.price.toString())
    );
  } else {
    console.log("Select the filter");
  }
  if (selectedOption === "2") {
    const highToLow = "Price - High to Low";
    return dispatch({
      type: SEARCH_ITEM,
      payload: { items: { [highToLow]: store } },
    });
  } else if (selectedOption === "3") {
    const lowToHigh = "Price - Low to High";
    return dispatch({
      type: SEARCH_ITEM,
      payload: { items: { [lowToHigh]: store } },
    });
  }
};

export const clearSearch = (data) => (dispatch) => {
  return dispatch({ type: CLEAR_SEARCH, payload: data });
};

export const setFavoriteRest = (token, rest_id, type) => (dispatch) => {
  return Axios.post(ADD_TO_FAVORITE_RESTAURANT_URL, {
    token: token,
    id: rest_id,
    type: type,
  })
    .then((response) => {
      const restaurant = response.data;
      if (type === "restaurant") {
        return dispatch({
          type: SET_FAVORITE_REST,
          payload: restaurant,
        });
      }
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const mappedRestaurant = (token, rest_id, type) => (
  dispatch,
  getState
) => {
  return Axios.post(ADD_TO_MAPPED_RESTAURANT_URL, {
    token: token,
    action: type,
    restaurant_id: rest_id,
  })
    .then((response) => {
      const state = getState();
      // console.log(state);
      const cartProducts = state.cart.products;
      for (let i = cartProducts.length - 1; i >= 0; i--) {
        // remove all items from cart
        cartProducts.splice(i, 1);
      }

      dispatch(updateCart(cartProducts));

      const data = response;
      return dispatch({ type: SET_MAPPED_REST, payload: data });
    })
    .catch(function(error) {
      console.log(error);
    });
};
